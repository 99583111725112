.SideBar {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 90px;
    position: fixed;
}

.logo {
    width: 58px;
    height: 180px;
    position: relative;
    margin-top: 5vh;
}

.logo .logoImage {
    width: 155px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
  }

.sidebar-line {
    background-color: black;
    width: 1px;
    flex-grow: 1;
    margin-bottom: 15vh;
    margin-top: 18vh;
}

.year {
    font-size: 12px;
    letter-spacing: 5px;
    transform: rotate(-90deg) translate(50%);
    padding-right: 4vh;
    padding-left: 5vh;
}

@media screen and (max-width: 850px) {
    .SideBar {
        height: 20%;
        width: 4rem;
        position:relative;
    }
    .logo .logoImage {
        width: 155px;
        transform: none;
      }

      .logo {
        margin-top: 0.5vh;
        height: 5rem;
    }

    .sidebar-line {
        display:none;
    }

    .year {
        display: none;
    }
 }

 @media screen and (min-width: 851px) and (max-width: 943px) {
    .SideBar {
        height: 90%;
    }
 }