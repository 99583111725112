
.text-columns {
    display: grid;
    grid-template-columns: 1fr 1.5fr; 
    padding-right:20px;
    margin-top: 20vh;
}

.section-introduction {
    align-items: flex-end;
    color:#222831;
    display: flex;
    font-size: 19px;
    font-weight:bolder;
    justify-content: flex-end;
    letter-spacing: 1.2px;
    margin-left: 130px;
    padding: 20px;
    text-align: left;
}

.section-menu {
    padding: 20px;
    margin-left: 20px;
}

.menu {
    color: #2a200b;
    display:block;
    font-family:'Bungee Shade', cursive;
    font-size:6rem;
    text-align: left;
    text-decoration: none;
}

.menu:hover {
    color:#876439;
}

@media screen and (max-width: 850px) { 
    .text-columns {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 2vh;
        padding-left: 0;
    }

    .section-introduction {
        color:#222831;
        font-size: 1.3rem;
        font-weight:bolder;
        letter-spacing: 1px;
        margin-left: 1.5rem;
        margin-right: 3rem;
        text-align: left;
    }

    .menu {
        font-size:3.5rem;
    
    }
}

@media screen and (min-width: 851px) and (max-width: 943px) {
    .section-introduction {
        margin-left: 3.5rem;
    }


.section-menu {
    margin-left: 0px;
    padding-left: 0px;
}
}