.Projects {
    flex-grow: 1;
    padding-left: 5vw;
    text-align: left;
}

.project-foto {
    padding-right: 4vw;
    border-radius: 39px;
    margin-top: 4rem;
}

.project-description {
    display: grid;
    grid-template-columns: 2fr 1fr;
    max-width: 89%;
}

.project-link {
    font-size: calc(1rem + 0.1vw);
    text-align: right;

}

.project-link a:link {
    text-decoration: none;
    color: #2a200b;
    font-weight: 300;
}

.project-zakarauskas {
    padding-top: 20px;
}

.project-link a:hover {
    color: #2a200b;
    text-decoration: none;
    font-weight: 500;
}

.project-link a:visited {
    text-decoration: none;
    color: #2a200b;
}


.section-projects {
    padding-bottom: 10rem;

}

@media screen and (min-width: 944px) and (max-width: 1411px) {

    .project-description p {
        font-size: calc(1rem+0.5vw);
        width: 90%;
        letter-spacing: 0.5px;
    }

    .project-foto {
        width: 45%;
    }

}

@media screen and (min-width: 851px) and (max-width: 943px) {
    
    .project-foto {
       display: block;
        margin: auto;
        margin-top: 1.5rem;
        padding-right: 4rem;

    }

    .project-description {
        margin: auto;
        width: 80%;
        padding-bottom: 2rem;
    }

    .project-link {
        padding-right: 1rem;
        font-size: calc(0.8rem + 0.1vw);
    }
}

@media screen and (max-width: 850px) { 
    .Projects {
        padding-left: 5vw;
    }

    .Projects h1 {
        margin-left: -1rem;
    }
    
    .project-foto {
        display: block;
         margin: auto;
         margin-top: 1rem;
         padding-right: 3rem;
         width: 90%;
 
     }

    
     .project-description {
        display: block;
        width: 100%;
        color: black;
        letter-spacing: 1px;
    }

    .project-description {
        margin-left: 0.5rem;
    }
 }