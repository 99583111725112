.App {
  text-align: center;
  background-color: #D4D1C3;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #2a200b;
}

.app-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
  max-width: 1500px;

}

.content-container {
  margin-left: 4vw;
}

@media screen and (max-width: 850px) {
  .app-container {
    flex-direction: column;
  }
  
  .content-container {
    margin-left: 2vw;
  }

  .title {
    font-size: 3.5rem;
  }
 }


 @media screen and (min-width: 851px) and (max-width: 943px) {
  .title {
    font-size: 5.5rem;
  }
 }