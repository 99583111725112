.About {
    padding-left: 5vw;
}

.sections {
    display: grid;
    grid-template-columns: 1fr 3fr;
    column-gap: 2vh;

}

.section {
    max-width: 60%;
    text-align: left;
}

.foto-wrap {
    width: 100%;
    resize: horizontal;
    max-width: 95%;
    height: calc(100vh - 16px);
}

.section-foto {
    aspect-ratio: 2 / 3;
    background-image: url(./media/foto.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    margin: 1%;
    border-radius: 45px 0px 0px 0px;
    box-sizing: border-box;

}

.section-about {
    padding-top: 10vw;
}

.section-about .ButtonMailto {
    text-decoration: none;
    margin-top: 6vh;
}





@media screen and (max-width: 600px) {

    .sections {
        display: block;
        margin-left: -0.5rem;
        padding-left: 5vw;
        ;
    }

    .foto-wrap {
        height: calc(35vh - 16px);
        max-width: 86%;
    }

    .section-foto {
        background-image: url(./media/foto_cropped.jpg);
        width: 100%;
    }


    .section {
        max-width: 90%;
    }

    .section p {
        font-size: 1rem;
        letter-spacing: 0.5px;
    }

    .section-about .ButtonMailto {
        margin-bottom: 10vh;
        font-size: 1rem;
    }


}

/* @media screen and (min-width: 851px) and (max-width: 943px) {
    .About {
        padding-left: 5rem;
    }

    .sections {
        display: grid;
        grid-template-columns: 1fr 3fr;
        column-gap: 0vh;
    }

    .section {
        max-width: 90%;
    }

    .section-foto {
        background-image: url(./media/foto.jpg);
    }



} */