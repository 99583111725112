.Contacts {
    padding-left: 6vw;
    padding-right: 15vw;

}

.section-contact {
    text-align: left;
    margin: 1% 10%;
}
.section-info{
    display: flex;
    justify-items: flex-end;
    margin-top: 25vh;

}

.cs-info {
    text-align: right;
    padding: 45px 45px 45px 115px;
    width: 60vw;

}

.section-contact .ButtonMailto {
    display:inline-block;
    text-decoration: none;
    margin-top: 10vh;
    font-size: 1.2rem;

}

/* .dot {
    height: 325px;
    width: 325px;
    border: 1px solid black;
    border-radius: 50%;
    display: inline-block;
    position: fixed;
  top: 90%;
  left: 65%;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  } */


@media screen and (max-width: 850px) {
    .Contacts {
    text-align: left;
    padding-left: 7vw;
    }
    
    .section-contact {
        width: 100%;
        margin:0;
    }

    .section-info{
    margin-top: 5rem;
    margin-bottom: 5rem;
    }

    p {
        padding:0;
    }

    .section-contact .ButtonMailto {
        margin-top: 6vh;
        font-size: 1rem;
        padding: 20px 30px;
    }

    .cs-info {
        padding: 0;
        width: 90%;
    
    
    }
    
 }

 @media screen and (min-width: 851px) and (max-width: 943px) {
    p {
    font-size: calc(1rem + 0.5vw);
    padding: 1rem 4rem;
    }
 }