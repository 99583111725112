body {
  background-color: #D4D1C3;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Bungee Inline', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
  font-size: 14px;
}

h1 {
  font-size: calc(1rem + 4vw);
  margin-top: 15vh;
  font-family: 'Bungee Shade', cursive;
  width: 100%;
  color: #876439;
  font-weight: normal;
  text-align: center;
}

h2 {
  font-size: calc(1rem + 0.5vw);
  font-weight:800;
}

p {
  font-size: calc(1rem + 0.3vw);
  font-weight: 700;
  letter-spacing: 1px;
}


@media screen and (max-width: 850px) { 
  h1 {
    font-size: calc(3rem + 4vw);
    margin-top: 5vh;
  }

}