.ButtonMailto {
    font-weight: 700;
    background-color:#1a1818;
    letter-spacing: 1px;
    border-radius: 50px;
    padding: 20px 60px;
    font-size: 24px;
    font-weight: 300;
    transition: padding .25s ease-in-out;
    display: inline-block;
    color:white;
}

.ButtonMailto:hover {
    font-weight: bold;
}